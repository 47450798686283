import GATSBY_COMPILED_MDX from "/opt/build/repo/content/realisations/droga-powiatowa-1507c-etap-2b.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import Header from '../components/header';
import Title from '../components/title';
import Seo from '../components/seo';
import Container from '../components/container';
import AnimationWrapper from '../components/animationWrapper';
import DataTable from '../components/dataTable';
import LightboxImage from '../components/lightboxImage';
import Carousel from '../components/carousel';
import InnerSection from '../components/innerSection';
import Layout from '../components/layout';
import "./realisationPage.css";
const RealisationPage = ({data, location, children}) => {
  const loadGallery = data.realisation.frontmatter.carousel_gallery.images.some(image => image.status === 'active');
  const getYouTubeVideoId = url => {
    const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(pattern);
    return match && match[1];
  };
  let videoID;
  if (data.realisation.frontmatter.youtube) {
    videoID = getYouTubeVideoId(data.realisation.frontmatter.youtube);
  }
  const file = data.realisation.internal.contentFilePath.split('/').pop();
  const realisationSlug = file.slice(0, file.lastIndexOf('.'));
  return React.createElement(Layout, {
    location: location,
    path: realisationSlug
  }, React.createElement(Seo, {
    title: `${data.realisation.frontmatter.title} - Transpol Lider`
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    pageName: data.realisation.frontmatter.title,
    data: data.header
  })), React.createElement("section", {
    id: "realisation-title"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, null, React.createElement(Title, {
    title: data.realisation.frontmatter.description
  })))), React.createElement("section", {
    id: "realisation-description",
    className: "page-section"
  }, React.createElement(InnerSection, {
    className: `realisation-description description-section justify-left ${data.realisation.frontmatter.map_image ? "" : "no-image"}`
  }, data.realisation.frontmatter.map_image && React.createElement("div", {
    className: "image-container"
  }, React.createElement(AnimationWrapper, {
    className: "realisation-map-wrapper"
  }, React.createElement(LightboxImage, {
    image: data.realisation.frontmatter.map_image,
    className: "image-container",
    objectFit: "contain",
    alt: data.realisation.frontmatter.title
  }))), React.createElement("div", {
    className: `description-container justify-left`
  }, React.createElement("div", {
    className: "section-description justify-left"
  }, children), data.realisation.frontmatter.data_table_values && React.createElement(DataTable, {
    data: data.realisation.frontmatter.data_table_values,
    location: data.realisation.frontmatter.data_table_style
  })))), videoID && React.createElement(Container, null, React.createElement(AnimationWrapper, {
    className: "youtube-wrapper"
  }, React.createElement("iframe", {
    width: "100%",
    height: "100%",
    src: `https://www.youtube.com/embed/${videoID}`,
    title: "YouTube video player",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    allowFullScreen: true
  }))), loadGallery && React.createElement("section", {
    id: "realisation-carousel-gallery"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    className: "description-medium"
  }, React.createElement(Carousel, {
    data: data.realisation.frontmatter.carousel_gallery,
    location: "realizacje"
  })))));
};
export const query = graphql`
  query(
      $slug: String,
      $headerPath: String,
  ) {
    realisation: mdx(internal: { contentFilePath: { regex: $slug } }) {
      frontmatter {
        title
        type
        active
        youtube
        image {
          childImageSharp {
            gatsbyImageData(webpOptions: {quality: 95})
          }
        }
        map_image{
          childImageSharp{
            gatsbyImageData(webpOptions: {quality: 95})
          }
        }
        data_table_style
        data_table_values{
          property
          value
        }
        carousel_gallery{
          text_section{
            title
            title_visible
            subtitle
            text
            button_text
            button_url
            button_style
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            description
            status
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        description
      }
      internal {
        contentFilePath
      }
    }
    header: file(relativePath: {eq: $headerPath}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
RealisationPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(RealisationPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
